
module.exports = {
    common: {
        enter: '入力お願いします',
        toastFail: "失敗",
        submit: '送信',
        welcome: "ようこそ",
        confirm: '確認',
        check: 'チェック',
        privacyPolicy: "プライバシーポリシー",
    },
    enterEmail: {
        tips: "認証用メールアドレスをご入力ください",
        toastSuccess: '成功',
        toastFail: '失敗',
    },
    jpnRegistration: {
        counter: '店名',
        localFirstName: '名',
        localLastName: '姓',
        localFirstName2: '名（カタカナ）',
        localLastName2: '姓（カタカナ）',
        phone: '携帯電話',
        email: 'eメールアドレス',
        day: "日",
        month: "月",
        year: "年",
        birth: 'お誕生日',
        gender: '性別',
        male: '男性',
        female: '女性',
        select: '選択',
        address: '町名番地',
        zipCode: '〒',
        prefectures: '都道府県',
        municipalities: '市区町村',
        pInfoAgree: '私は、ELCジャパン合同会社/Estée Lauder Companiesの{0}に同意します。また、百貨店、小売業者、その他商業施設の運営者（百貨店等）の施設内において、お客様がELCジャパン合同会社（以下、「ELC」）に対しお客様の個人情報を提供された場合、ELCが当該プライバシーポリシーに基づき当該百貨店等に当該個人情報を提供する場合があることに同意します。​',
        promotionAgree1: 'Eメール、SNS、SMS、DM等でのお知らせを希望します。',
        promotionAgree2: '(製品の最新情報やお得な  キャンペーン情報をお届けします。不要な場合は、チェックを外してください。)',
        errMessageFirstName: '名前を入力してください',
        errMessageLastName: '苗字を入力してください',
        errMessageFirstName2: '名前（カタカナ）を入力してください',
        errMessageLastName2: '苗字（カタカナ）を入力してください',
        errMessageEmail: '有効なメールアドレスを入力してください',
        errMessagePhone: '携帯番号を入力してください',
        errMessageBirthDay: 'お誕生日を入力してください',
        errMessageBirthMonth: 'お誕生日月を入力してください',
        errMessagePersonalInfo: 'プライバシーポリシーへの同意をお願いいたします',
        errMessageZipCode: '正しい郵便番号を入力してください',
        alreadyRegistered: 'このメールアドレスは既に登録済みです',
    },
    barcode: {
        wel: '{0}にご登録ありがとうございます。本バーコードをカウンターにてご提示ください。',
    },
}
